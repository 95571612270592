import axios from 'axios'
import store from '@/store'
import router from '@/router'

const request = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	validateStatus() {
		return true
	}
})

request.interceptors.request.use((config) => {
		config.headers['Accept'] = 'application/vnd.api+json'
		config.headers['Content-Type'] = 'application/vnd.api+json'

		return config
	}, (error) => {
		return Promise.reject(error)
	}
)

request.interceptors.response.use((response) => {
		if (response.status === 401) {
			store.dispatch('user/clear')
			localStorage.removeItem('access_token')
			setTimeout(() => {
				router.push({ name: 'Login' })
			}, 5000)
		}

		return response
	}, (error) => {
		return Promise.reject(error)
	}
)

const parse = (response) => {
	return {
		data: response.data,
		status: response.status
	}
}

const isError = (status) => {
	return status > 299
}

export { request, parse, isError }
