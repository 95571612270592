export default [
  {
    path: '/',
    name: 'Main',
    redirect: { name: 'Profile' }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Any',
    redirect: { name: 'PageNotFound' }
  },
  {
    path: '/404',
    name: 'PageNotFound',
    component: () => import('@/views/404.vue'),
    meta: {
      title: 'Страница не найдена',
      auth: 'full'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      title: 'Вход',
      auth: 'full'
    }
  },
  {
    path: '/forgot',
    name: 'PasswordForgot',
    component: () => import('@/views/PasswordForgot'),
    meta: {
      title: 'Сброс пароля',
      auth: 'full'
    }
  },
  {
    path: '/reset',
    name: 'PasswordReset',
    component: () => import('@/views/PasswordReset'),
    meta: {
      title: 'Сброс пароля',
      auth: 'full'
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('@/views/Users.vue'),
    params: {
      page: 1,
      per_page: 3
    },
    meta: {
      title: 'Пользователи',
      group: 'users',
      auth: 'users'
    }
  },
  {
    path: '/users/:userId',
    alias: '/projects/:projectId/users/:userId',
    name: 'EditUser',
    component: () => import('@/views/EditUser.vue'),
    props: true,
    meta: {
      title: 'Редактирование пользователя',
      group: 'users',
      auth: 'editUser'
    }
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('@/views/Projects.vue'),
    meta: {
      title: 'Проекты',
      group: 'projects',
      auth: 'projects'
    }
  },
  {
    path: '/projects/:id',
    name: 'EditProject',
    component: () => import('@/views/EditProject.vue'),
    meta: {
      title: 'Редактирование проекта',
      group: 'projects',
      auth: 'editProject'
    }
  },
  {
    path: '/schedule',
    name: 'Schedule',
    component: () => import('@/views/Schedule.vue'),
    meta: {
      title: 'График работы',
      group: 'schedule',
      auth: 'schedule'
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/Profile.vue'),
    meta: {
      title: 'Профиль',
      group: 'profile',
      auth: 'full'
    }
  }
]
