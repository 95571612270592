import { request, parse } from '@/utils/request'

const list = async (token, query, include) => {
  if (include !== undefined) {
    query.include = include.join(',')
  }

  const response = await request.get('/projects', {
    headers: {
      'Authorization': `Bearer ${token}`
    },
    params: {
      ...query
    }
  })

  return parse(response)
}

const get = async (token, id, include) => {
  const response = await request.get('/projects/' + id, {
    headers: {
      'Authorization': `Bearer ${token}`
    },
    params: {
      include: include ? include.join(',') : null
    }
  })

  return parse(response)
}

const create = async (token, name) => {
  const response = await request.post('/projects', {
    data: { name }
  },{
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })

  return parse(response)
}

const update = async (token, id, name) => {
  const response = await request.patch('/projects/' + id, {
    data: { name }
  },{
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })

  return parse(response)
}

const destroy = async (token, id) => {
  const response = await request.delete('/projects/' + id, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })

  return parse(response)
}

export { list, get, create, update, destroy }