const hasAccessToPage = (to, role) => {
  const permissions = [
    {
      roles: ['super_admin'],
      pages: [
        'users',
        'editUser',
        'projects',
        'editProject'
      ]
    },
    {
      roles: ['project_admin'],
      pages: [
        'editProject',
        'editUser'
      ]
    },
    {
      roles: ['driver', 'collector'],
      pages: [
        'schedule'
      ]
    }
  ]

  if (to.meta.auth === 'full') {
    return true
  }

  for (const accessObject of permissions) {
    if (accessObject.roles.indexOf(role) === -1) {
      continue
    }

    if (accessObject.pages.indexOf(to.meta.auth) !== -1) {
      return true
    }
  }

  return false
}

export { hasAccessToPage }