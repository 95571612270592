import { createRouter, createWebHistory } from 'vue-router'
import routes from '@/router/routes'
import store from '@/store'
import { hasAccessToPage } from '@/utils/auth'

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title

  const token = localStorage.getItem('access_token')

  if (token !== null) {
    store.dispatch('user/setToken', token)
  }

  // redirect for unauthorized users
  if (
    ! store.getters['user/isAuthorized']
    && to.name !== 'Login'
    && to.name !== 'PasswordForgot'
    && to.name !== 'PasswordReset'
    && to.name !== 'PageNotFound'
  ) {
    return next({ name: 'Login' })
  }

  // redirect to home page
  if (
    store.getters['user/isAuthorized']
    && (to.name === 'Login'
    || to.name === 'PasswordForgot'
    || to.name === 'PasswordReset')
  ) {
    return next({ name: 'Profile' })
  }

  next()
})

router.afterEach(async (to, from) => {
  if (store.getters['user/isAuthorized'] && ! store.getters['user/isProfileDownloaded']) {
    await store.dispatch('user/getProfile')
  }

  // redirect to 404 if user does not have permissions
  if (! hasAccessToPage(to, store.state.user.profile.role.slug)) {
    router.push({ name: 'PageNotFound' })
  }
})

export default router
