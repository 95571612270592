import { signIn, signOut, profile } from '@/api/auth'
import { get as getProject } from '@/api/projects'
import { isError } from '@/utils/request'
import { deserialize } from 'deserialize-json-api'

export default {
  namespaced: true,

  state() {
    return {
      token: undefined,
      profile: {
        id: undefined,
        avatar: undefined,
        login: undefined,
        email: undefined,
        first_name: undefined,
        last_name: undefined,
        patronymic: undefined,
        is_notifiable: undefined,
        role: {
          name: undefined,
          slug: undefined
        },
        extra: {
          address: undefined,
          allowed_ttk: undefined,
          car_booth_size: undefined,
          disembark_time: undefined,
          phone: undefined,
          vehicle_load_capacity: undefined
        },
        project: {
          id: undefined,
          name: undefined,
          requests_quantity: undefined
        }
      },
      profileIsDownloaded: false
    }
  },

  mutations: {
    setToken(state, token) {
      state.token = token
    },

    removeToken(state) {
      state.token = undefined
    },

    putProfile(state, profile) {
      state.profile = profile
      state.profileIsDownloaded = true
    },

    clearProfile(state) {
      state.profile = {
        id: undefined,
        avatar: undefined,
        login: undefined,
        email: undefined,
        first_name: undefined,
        last_name: undefined,
        patronymic: undefined,
        role: {
          name: undefined,
          slug: undefined
        },
        extra: {
          address: undefined,
          allowed_ttk: undefined,
          car_booth_size: undefined,
          disembark_time: undefined,
          phone: undefined,
          vehicle_load_capacity: undefined
        },
        project: {
          id: undefined,
          name: undefined,
          requests_quantity: undefined
        }
      }
      state.profileIsDownloaded = false
    },

    setValueProjectRequestsQuantity(state, value) {
      state.profile.project.requests_quantity = value
    }
  },

  actions: {
    setToken({ commit }, token) {
      commit('setToken', token)
    },

    async signIn({ commit }, payload) {
      const response = await signIn(payload.login, payload.password);

      if (isError(response.status)) {
        return response
      }

      const token = deserialize(response.data).data.access_token

      commit('setToken', response.token)
      localStorage.setItem('access_token', token)

      return response
    },

    signOut({ state, commit }) {
      signOut(state.token)

      commit('clearProfile')
      commit('removeToken')

      localStorage.removeItem('access_token')
    },

    async getProfile({ state, commit }) {
      commit('clearProfile')

      const response = await profile(state.token, ['project'])

      if (isError(response.status)) {
        commit('removeToken')
        localStorage.removeItem('access_token')

        return response
      }

      commit('putProfile', deserialize(response.data).data)

      return response
    },

    async updateProject({ state, commit }) {
      const response = await getProject(state.token, state.profile.project.id)

      if (isError(response.status)) {
        return response
      }

      commit('setValueProjectRequestsQuantity', deserialize(response.data).data.requests_quantity)
    },

    clear({ commit }) {
      commit('clearProfile')
      commit('removeToken')
    }
  },

  getters: {
    isAuthorized(state) {
      return state.token !== undefined
    },

    isProfileDownloaded(state) {
      return state.profileIsDownloaded
    }
  }
}