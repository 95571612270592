import { request, parse } from '@/utils/request'
import axios from 'axios'

const signIn = async (login, password) => {
  const response = await request.post('/login', {
    data: {
      login,
      password
    }
  })

  return parse(response)
}

const signOut = async (token) => {
  const response = await request.post('/logout', {}, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })

  return parse(response)
}

const forgot = async (email) => {
  const response = await request.post('/password/forgot', {
    data: { email }
  })

  return parse(response)
}

const reset = async (token, email, password) => {
  const response = await request.post('/password/reset', {
    data: {
      token,
      email,
      password,
      password_confirmation: password
    }
  })

  return parse(response)
}

const profile = async (token, include) => {
  const response = await request.get('/profile', {
    headers: {
      'Authorization': `Bearer ${token}`
    },
    params: {
      include: include.join(',')
    }
  })

  return parse(response)
}

const updateProfile = async (token, data) => {
  const response = await request.patch('/profile', { data }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
  )

  return parse(response)
}

const uploadAvatar = async (token, file) => {
  const formData = new FormData()
  formData.append('file', file)

  const response = await axios.post(`${process.env.VUE_APP_API_URL}/profile/avatar`, formData, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'multipart/form-data'
    }
  })

  return parse(response)
}

export { signIn, signOut, forgot, reset, profile, updateProfile, uploadAvatar }
