<template>
  <router-view/>
  <notifications/>
</template>

<script>
export default {
  name: 'Main'
}
</script>

<style lang="scss">
@import "assets/scss/main";
</style>
