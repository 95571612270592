import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import Notifications from '@kyvg/vue3-notification'
import VCalendar from 'v-calendar'
import 'v-calendar/dist/style.css'

createApp(App)
  .use(store)
  .use(router)
  .use(Notifications)
  .use(VCalendar)
  .mount('#app')
